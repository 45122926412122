form {
}

form fieldset {
  margin-bottom: 30px;
}

form .row {
  margin-bottom: 10px;
}

form .footer {
  margin: 10px 0;
}

form .footer button {
  margin-right: 10px;
}
