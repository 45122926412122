.Sign {
  width: 100%;
  height: 100vh;
}

.Sign__Card {
  width: 450px;
  margin: 16px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  flex: 0 0 auto;
  text-decoration: none;
  color: #2b4660;
  padding: 16px;
}

.Sign__Content {
  min-height: 50%;
  width: 100%;
}

.Sign__Content h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.Sign button[type="submit"] {
  margin-top: 2rem;
  width: 100%;
  background-color: #f6c844;
  color: #0d2339;
  border: none;
  padding: 10px 0;
  font-size: 14px;
  border-radius: 4px;
  text-transform: uppercase;
}

.Sign__Footer {
  text-align: center;
  margin: 16px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

.Sign__Footer .Blue__Link {
  color: var(--blue-600);
}
