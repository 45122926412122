.PersonCard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PersonCard__ImgContainer {
  width: 60px;
  margin-right: 20px;
}
.PersonCard__ImgContainer img {
  width: 100%;
  border-radius: 50%;
}

.PersonCard__PrimaryText {
  font-size: 18px;
  font-weight: bold;
}
.PersonCard__SecondaryText {
  font-size: 14px;
  color: var(--color-gray-400);
}
