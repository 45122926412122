.PageLayout {
  background-color: #fff;
}

.PageLayout__Wrapper {
  padding: 30px 50px;
  width: 1080px;
  margin: 0 auto;
}

.PageLayout__Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  padding: 30px 0;

  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  position: sticky;
  top: 0;
}

.PageLayout__HeaderTitle h2 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 300;
  margin: 0 0;
}

.PageLayout__HeaderTitle p {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.PageLayout__HeaderActions a {
  font-weight: 300;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.PageLayout__PrimayBtn {
  text-decoration: none;
  border-radius: 20px !important;
  border: 1px solid #107aca;
  color: #107aca;
  font-weight: 500 !important;
}

.PageLayout__PrimayBtn:hover {
  color: #fff !important;
  background-color: #107aca;
}

.PageLayout__Content {
  padding: 30px 0;
  height: 100vh;
}
