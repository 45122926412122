.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
}

.App__Nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  bottom: 0;
  background-color: #eee;
}

.App__Main {
  margin-left: 250px;
  height: 100vh;
  overflow: auto;
}
