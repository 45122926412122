.Sidebar {
  height: 100vh;
  background-color: #107aca;
  border-right: 1px solid #eee;
}

.SidebarWrapper {
  padding: 5px;
  color: #555;
}

.SidebarMenu {
  margin: 10px 0;
}

.SidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.SidebarList {
  list-style: none;
  padding: 5px;
}

.link {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
  margin: 10px;
  position: relative;
}

.link li {
  display: flex;
  align-items: center;
}

.link.active,
.link:hover {
  background-color: #fff;
  color: #107aca;
}

.SidebarIcon {
  margin-right: 10px;
  font-size: 30px !important;
}
