.Profile {
  position: relative;
  background-color: #fff;
  height: 100vh;
}

.Profile__Wrapper {
  padding: 10px 50px;
  width: 1080px;
  margin: 0 auto;
}

.Profile__Header {
  background-color: #fff;
}

.Profile__Info {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.Profile__Cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Profile__Cover .Icon {
  font-weight: 400;
  font-size: 24px;
  border: 1px solid #107aca;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin-top: 10px;
}

.Profile__Cover .Icon:hover {
  opacity: 0.5;
}

.Profile__Info .Profile__Left {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.Profile__Info .Profile__Left img {
  width: 100%;
  border-radius: 20%;
}

.Profile__Info .Profile__Right {
}

.Profile__Nav {
  margin: 0;
}

.Profile__CoverActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Profile__CoverActions .Cover__Button {
  text-decoration: none;
  border-radius: 20px !important;
  border: 1px solid #107aca;
  color: #107aca;
  font-weight: 500 !important;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  margin-left: 5px;
  background-color: #fff;
}

.Profile__CoverActions .Cover__Button:hover {
  color: #fff !important;
  background-color: #107aca;
}

.Profile__Content .ProfileIcon {
  margin-right: 5px;
  font-size: 18px;
}

.Profile__Content .LabelIcon {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.Profile__Content .Info {
  color: #107aca;
}

.Profile fieldset {
  margin: 50px 0;
}
