.Avatar {
  margin-bottom: 10px;
}
.Avatar__Img {
  width: 100px;
  height: 100px;
  margin: 10px auto;
}

.Avatar__Img img {
  width: 100%;
  border-radius: 50%;
}
.Avatar__Info {
  text-align: center;
}
.Avatar__Info h4 {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 20px;
}

.Avatar__Info p {
  color: #eee;
  text-align: center;
  font-size: 14px;
}
.Avatar__Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Avatar__Actions .Item {
  list-style-type: none;
  color: #fff;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Avatar__Actions .Item:hover {
  background-color: #fff;
  color: #107aca;
}

.Avatar__Actions .Link {
  width: 40px;
  height: 40px;
}

.Avatar__Actions .Link .Badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 17px;
  height: 17px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  background-color: red;
}
