.table,
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 0;
}

table thead th,
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 18px;
  color: #8394a1;
  font-weight: 400;
}

table tr td,
.table tr td {
  font-size: 16px;
  border-bottom: 1px solid #cdd4d9;
  padding: 12px 10px;
  vertical-align: middle !important;
  padding: 0.75rem;
}

table .ButtonIconContainer {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}

table .ButtonIcon,
.table .ButtonIcon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px !important;
  height: 40px !important;
  color: #107aca;
  border: 1px solid #107aca;
}

table .ButtonIcon:hover,
.table .ButtonIcon:hover {
  background-color: #107aca;
  color: #fff;
}
